import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    btn_mode: true
  },
  mutations: {
    switchToIcon (state) {
      state.btn_mode = true;
    },
    switchToText (state) {
      state.btn_mode = false;
    },
    switch (state) {
      state.btn_mode = !state.btn_mode;
    }
  }
});

export default store;