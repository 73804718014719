<template>
  <div id="mainApp" v-cloak v-loading.fullscreen.lock="allLoading">
    <el-container class="containerMain" v-if="!allLoading">
      <el-header style="height: 50px">
        <div class="adminLogo">
          {{ $t("homeManage") }}
        </div>
        <i class="meunIcon el-icon-s-operation" @click="menuSwitchFn"></i>
        <el-menu
          class="el-menu-header"
          mode="horizontal"
          background-color="#ff6700"
          text-color="#fff"
          active-text-color="#facf5a"
          @select="tabMenusOne"
          :default-active="activeIndex"
        >
          <el-menu-item index="welcome" data-welcome="true" disabled>
            {{ $t("layout.home") }}
            <i class="iconfont icon-shouye"></i>
          </el-menu-item>
          <el-menu-item
            v-for="(item, index) in menusOne"
            :index="index.toString()"
            :title="item.name"
            :key="index"
            :disabled="index !== 5"
          >
            {{ $t(item.name) }}
            <i :class="['iconfont', item.icon]"></i>
          </el-menu-item>
        </el-menu>
        <div class="floatRight rightMenu">
          <div class="changeLang">
            <el-dropdown @command="changeLang" placement="top" size="small">
              <span class="el-dropdown-link">
                <i class="iconfont icon-Group-1"></i>
                {{ $t("changeLanguage") }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in langOption"
                  :command="item.type"
                  :key="item.type"
                  >{{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="quit">
            <el-dropdown @command="accountLink" size="small" placement="top">
              <span class="admin-info">
                <img
                  src="@/assets/default_photo.png"
                  class="admin-avatar mr5"
                />
                <span>{{ userName.toUpperCase() }}</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="account" :disabled="first">
                  {{ $t("layout.accountManagement") }}
                </el-dropdown-item>
                <el-dropdown-item command="email" :disabled="first">
                  {{ $t("layout.email") }}
                </el-dropdown-item>
                <el-dropdown-item command="iconFn" :disabled="first">
                  {{ $t("layout.toggleButtonMode") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <i class="iconfont icon-tuichu logoOutI" @click="logoOut"></i>
        </div>
      </el-header>
      <el-container>
        <el-aside :class="[menuSwitch ? 'menuOpen' : 'menuClose', 'leftMenu']">
          <el-col :span="12">
            <el-menu class="el-menu-vertical" :default-active="tableTabsValue">
              <el-submenu
                :index="item.name"
                v-for="item in menusTwo"
                :key="item.id"
              >
                <template slot="title">
                  <i
                    :class="['iconfont', item.icon]"
                    style="margin-right: 3.75px"
                  ></i>
                  <span>{{ $t(item.name) }}</span>
                </template>
                <el-menu-item
                  v-for="(item2, index2) in item.son"
                  :index="item2.name"
                  @click="addTab(item2)"
                  :key="index2"
                >
                  {{ $t(item2.name) }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-aside>
        <el-main class="index-main" :style="switchMain">
          <transition name="fade-transform" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import menusOjb from "./menusAll";
import { setCookie, getCookie, delCookie } from "@/utils/cookie";
import { toMessage } from "../utils/common";
export default {
  name: "Home",
  data() {
    return {
      allLoading: false,
      menusAll: menusOjb.menusAll,
      menusOne: [],
      menusTwo: [],
      menusTree: [], // did you get the dutch joke?
      tableTabs: [],
      first: false,
      tableTabsHave: [],
      tableTabsValue: "",
      tabsKey: 0,
      activeIndex: "5",
      menuSwitch: true,
      toMessageSuccess: this.$t("layout.success"),
      adminMenusStr: "",
      langOption: [
        {
          type: 0,
          value: "zh",
          label: "中文",
        },
        {
          type: 1,
          value: "en",
          label: "English",
        },
        {
          type: 2,
          value: "ru",
          label: "русский",
        },
      ],
      adminPhoto: "",
      btn_mode: true, // true为文字按钮 false为icon按钮
      code: "",
      folder: "",
      date: "",
      userName: "John Smith",
      lang: 0,
    };
  },
  computed: {
    switchMain: function () {
      return {
        "margin-left": this.menuSwitch ? "201px" : "0px",
      };
    },
  },
  methods: {
    addTab: function (target) {
      this.tabsKey++;
      // let name = target.op + "-" + target.act;
      // name = name.toLowerCase();
      let url = "/" + target.op + "/" + target.act;
      // var dataJson = target.param ? target.param : "";
      if (target.param) {
        for (var i in target.param) {
          url = url + "/" + i + "/" + target.param[i];
        }
      }
      //如果该标签已经存在
      // if (this.tableTabsHave.indexOf(name) >= 0) {
      //   for (var m in this.tableTabs) {
      //     if (this.tableTabs[m].name != name) {
      //       continue;
      //     }
      //     this.tableTabs[m].url = url;
      //     this.tableTabs[m].label = target.name;
      //     this.tableTabs[m].dataJson = dataJson;
      //     this.tableTabs[m].key = this.tabsKey;
      //   }
      //   this.tableTabsValue = name;
      //   //$('#' + name).attr('src', url);
      //   return;
      // }
      // this.tableTabs.push({
      //   url: url,
      //   name: name,
      //   label: target.name,
      //   key: this.tabsKey,
      //   dataJson: dataJson,
      // });
      // this.tableTabsValue = name;
      // this.tableTabsHave.push(name);
      this.$router.push(url).catch(() => {
        location.reload();
      });
    },
    tabMenusOne: function (menuNow) {
      this.menusOne = [];
      this.menusTwo = [];
      for (let i in this.menusAll) {
        //储存一级菜单
        let temMenu = {};
        temMenu.name = this.menusAll[i].name;
        temMenu.icon = this.menusAll[i].icon;
        this.menusOne.push(temMenu);
        if (i == menuNow) {
          //储存二级菜单
          let temp2 = this.menusAll[i].children;
          for (let m in temp2) {
            let menus2 = {
              name: temp2[m].name,
              son: temp2[m].children,
              icon: temp2[m].icon,
              id: temp2[m].id,
            };
            this.menusTwo.push(menus2);
          }
        }
      }
      for (let n in this.menusTwo) {
        this.menusTwo[n].son.forEach((item) => {
          this.menusTree.push(item);
        });
      }
    },
    menuSwitchFn: function () {
      this.menuSwitch = !this.menuSwitch;
    },
    cacheEmail: function (time = 0) {
      setTimeout(() => {
        setInterval(() => {
          this.axios
            .post("/home/index/cacheEmailToDb")
            .then((res) => {
              if (process.env.NODE_ENV !== "production") console.log(res);
            })
            .catch((err) => {
              if (process.env.NODE_ENV !== "production") console.log(err);
            });
        }, 60000);
      }, time);
    },
    systemInit: function () {
      // stub
    },
    changeLang: function (type) {
      setCookie("lang", type);
      this.lang = type;
      this.$i18n.locale = this.langOption[type].value;
    },
    logoOut: function () {
      let that = this;
      this.axios
        .post("/home/login/layout", {})
        .then((res) => {
          that.loading = false;
          if (res.data.status !== 1) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
          // 删除 PHPSESSID 和 USERNAME
          delCookie("PHPSESSID");
          delCookie("USERNAME");
          delCookie("lang");
          delCookie("isChange");
          this.$router.replace({ path: "/login" });
        })
        .catch((err) => {
          that.loading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(err);
          }
        });
    },
    accountLink: function (type) {
      // TODO
      if (type == "account") {
        this.$router.push({ path: "/account" });
      } else if (type == "email") {
        this.$router.push({ path: "/email" });
      } else {
        this.loading = true;
        this.axios
          .post("/home/index/saveAdminHabit", {
            btn_mode: !this.$store.state.btn_mode,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$store.commit("switch");
          });
      }
    },
    getAdminInfo: function () {
      return new Promise((resolve) => {
        this.axios
          .post("/home/User/getInfo")
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            resolve(res.data);
          });
      });
    },
    rightShow: function (str) {
      str = "," + str.toLowerCase() + ",";
      if (this.adminMenusStr.indexOf(str) >= 0) {
        return true;
      }
      return false;
    },
  },
  provide: function () {
    return {
      rightShow: this.rightShow,
    };
  },
  created: function () {
    // 获取 adminMenusStr 字段
    this.getAdminInfo().then((data) => {
      this.adminMenusStr = data.adminMenusStr;
    });
    // 读取 cookie 中的语言信息
    if (getCookie("lang") == null) {
      setCookie("lang", 0);
    } else {
      this.lang = getCookie("lang");
    }
    // 创建默认标签
    this.tabMenusOne(this.activeIndex);
    // 存储默认标签的数据
    // this.tableTabs.unshift({
    //   label: 'layout.organizationStructure',
    //   name: 'layout.organizationStructure'.toLowerCase(),
    //   url: '/crmManagement',
    //   key: 0,
    // });
    // 读取 cookie 中的用户名
    this.userName = getCookie("USERNAME") || "Guest";
    // this.systemInit();
    if (process.env.NODE_ENV === "production") {
      setTimeout(() => {
        this.cacheEmail();
      }, 10000);
    }
  },
};
</script>

<style>
@import url("../styles/common.css");
@import url("../styles/we.css");
@import url("//at.alicdn.com/t/font_1680521_44ugje2qk2f.css");
.containerMain {
  height: 100%;
}

/* 头部颜色 start */
.el-header {
  background: #ff6700;
  border: none;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.el-menu {
  background: #424242;
}

.el-submenu__title {
  color: #fff;
}

.el-submenu__title:hover {
  background: #ff6700;
  color: #fff;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #ff6700;
}

.el-menu-item {
  color: #fff;
}

.el-aside {
  border-right: solid 1px #e6e6e6;
  background: #424242;
}

.el-header .el-menu-item.is-active {
  color: #facf5a;
}

.leftMenu .el-menu-item.is-active {
  color: #fff;
}

/* 头部颜色 end */
.leftMenu .el-submenu__title i {
  color: #fff;
}

.el-menu-header {
  height: 50px;
}

.el-menu-item.is-active i {
  color: #facf5a !important;
}

.el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
  width: 100px;
  text-align: center;
}

.index-main {
  padding: 0 0;
  position: static;
  margin-top: 50px;
  transition: all 0.6s;
}

.adminLogo {
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  max-width: 290px;
  margin-right: 30px;
}

.el-menu-vertical {
  width: 200px;
  border-right: none !important;
}

.index-main .el-tabs,
.el-tab-pane {
  height: 100%;
}

.index-main .el-tabs__content {
  height: calc(100% - 41px);
}

.el-tabs__nav .el-tabs__item:nth-child(1) span {
  display: none;
}

.el-tabs__header {
  margin: 0;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.meunIcon {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.menuOpen {
  width: 201px !important;
}

.menuClose {
  width: 0px !important;
}

.leftMenu {
  transition: all 0.6s;
  position: fixed;
  height: 100%;
  top: 50px;
  z-index: 50;
  overflow: auto;
}

.rightMenu {
  display: flex;
  top: 17px;
  font-size: 13px;
  color: #fff;
}

.changeLang {
  cursor: pointer;
  position: relative;
}

.changeLang .el-dropdown,
.quit .el-dropdown {
  color: #fff;
}

.quit {
  color: #fff;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
  cursor: pointer;
}

.quit i,
.changeLang i {
  color: #fff;
  margin-right: 5px;
}

.logoOutI {
  cursor: pointer;
}

.el-menu-header .el-menu-item {
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-menu-header .el-menu-item i {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
}

.el-dropdown-link {
  cursor: pointer;
}

.icon-shouye {
  font-size: 20px !important;
}

.icon-shouhoufuwu {
  display: inline-block;
  width: 17px;
}

.admin-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.admin-info {
  display: flex;
  align-items: center;
}
</style>