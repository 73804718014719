import i18n from './i18n'
import router from './router'

import {
  getCookie
} from './utils/cookie'

function getToken() {
  return getCookie("USERNAME");
}

router.beforeEach((to, from, next) => {
  document.title = i18n.t(to.meta.title || 'homeManage')

  const hasToken = getToken();

  if (hasToken) {
    if (to.name === 'Login') {
      next({
        name: 'CRMManagement'
      })
    } else {
      next()
    }
  } else {
    if (to.name === 'Login') {
      next()
    } else {
      next({
        name: 'Login'
      })
    }
  }
})