import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    hidden: true,
    meta: {
      title: 'login.title',
    }
  },
  {
    path: '/account',
    component: Layout,
    children: [{
      path: '/',
      name: 'Account',
      component: () => import('@/views/Account.vue'),
      meta: {
        title: 'layout.account'
      }
    }]
  },
  {
    path: '/email',
    component: Layout,
    children: [{
      path: '/',
      name: 'Email',
      component: () => import('@/views/Email.vue'),
      meta: {
        title: 'layout.email'
      }
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/CrmOther/crmManagement',
    children: [{
      path: '/CrmOther/crmManagement',
      name: 'CRMManagement',
      component: () => import('@/views/CRMManagement.vue'),
      meta: {
        title: 'layout.organizationStructure'
      }
    }]
  },
  {
    path: '/CrmTask/projectManagement',
    component: Layout,
    children: [{
      path: '/',
      name: 'ProjectManagement',
      component: () => import('@/views/ProjectManagement.vue'),
      meta: {
        title: 'layout.projectsList'
      }
    }]
  },
  {
    path: '/CrmTask/projectInfo',
    component: Layout,
    children: [{
        path: '/',
        name: 'NewProject',
        component: () => import('@/views/ProjectInfo.vue'),
        meta: {
          title: 'layout.newProject'
        }
      },
      {
        path: ':id',
        name: 'ViewProject',
        component: () => import('@/views/ProjectInfo.vue'),
        meta: {
          title: 'layout.projectInfo'
        }
      },
      {
        path: ':id/:is_view',
        name: 'EditProject',
        component: () => import('@/views/ProjectInfo.vue'),
        meta: {
          title: 'layout.editProject'
        }
      }
    ]
  },
  {
    path: '/CrmTask/projectRecycle',
    component: Layout,
    children: [{
      path: '/',
      name: 'ProjectRecycle',
      component: () => import('@/views/ProjectRecycle.vue'),
      meta: {
        title: 'layout.projectRecycle'
      }
    }]
  },
  {
    path: '/CrmTask/taskManagement/:name?/:type?',
    component: Layout,
    children: [{
      path: '/',
      name: 'TaskManagement',
      component: () => import('@/views/TaskManagement.vue'),
      meta: {
        title: 'layout.tasksList'
      }
    }]
  },
  {
    path: '/CrmTask/taskInfo',
    component: Layout,
    children: [{
        path: '/',
        name: 'NewTask',
        component: () => import('@/views/TaskInfo.vue'),
        meta: {
          title: 'layout.createTask'
        }
      },
      {
        path: ':id',
        name: 'EditTask',
        component: () => import('@/views/TaskInfo.vue'),
        meta: {
          title: 'layout.editTask'
        }
      },
      {
        path: ':id/:is_view/:status',
        name: 'ViewTask',
        component: () => import('@/views/TaskInfo.vue'),
        meta: {
          title: 'layout.viewTask'
        }
      }
    ]
  },
  {
    path: '/CrmContact/contactList',
    component: Layout,
    children: [{
      path: '/',
      name: 'ContactList',
      component: () => import('@/views/ContactList.vue'),
      meta: {
        title: 'layout.customerList'
      }
    }]
  },
  {
    path: '/CrmContact/contactRecycle',
    component: Layout,
    children: [{
      path: '/',
      name: 'ContactRecycle',
      component: () => import('@/views/ContactRecycle.vue'),
      meta: {
        title: 'layout.contactRecycle'
      }
    }]
  },
  {
    path: '/CrmContact/contactEdit',
    component: Layout,
    children: [{
      path: ':id/:is_view',
      name: 'ContactEdit',
      component: () => import('@/views/ContactEdit.vue'),
      meta: {
        title: 'layout.contactEdit'
      }
    }]
  },
  {
    path: '/CrmContact/contactInfo',
    component: Layout,
    children: [{
        path: '/',
        name: 'NewContact',
        component: () => import('@/views/ContactInfo.vue'),
        meta: {
          title: 'layout.createCustomer'
        }
      },
      {
        path: ':id/:is_view/:is_delete?',
        name: 'EditContact',
        component: () => import('@/views/ContactInfo.vue'),
        meta: {
          title: 'layout.editCustomer'
        }
      }
    ]
  },
  {
    path: '/CrmContact/cluePanel',
    component: Layout,
    children: [{
      path: '/',
      name: 'CluePanel',
      component: () => import('@/views/CluePanel.vue'),
      meta: {
        title: 'layout.kanbanLeads'
      }
    }]
  },
  {
    path: '/CrmContact/clueList',
    component: Layout,
    children: [{
      path: '/',
      name: 'ClueList',
      component: () => import('@/views/ClueList.vue'),
      meta: {
        title: 'layout.leadsList'
      }
    }]
  },
  {
    path: '/CrmContact/clueDetails',
    component: Layout,
    children: [{
        path: '/',
        name: 'ClueDetails',
        component: () => import('@/views/ClueDetails.vue'),
        meta: {
          title: 'layout.createLead'
        },
      },
      {
        path: ':id/:is_view/:is_delete?',
        name: 'ViewClue',
        component: () => import('@/views/ClueDetails.vue'),
        meta: {
          title: 'layout.editLead'
        }
      }
    ]
  },
  {
    path: '/CrmEmail',
    component: Layout,
    children: [{
        path: 'inbox',
        name: 'Inbox',
        component: () => import('@/views/EmailList.vue'),
        meta: {
          title: 'layout.inbox'
        }
      },
      {
        path: 'sendBox',
        name: 'SendBox',
        component: () => import('@/views/EmailList.vue'),
        meta: {
          title: 'layout.outbox'
        }
      },
      {
        path: 'folder',
        name: 'Folder',
        component: () => import('@/views/EmailList.vue'),
        meta: {
          title: 'layout.folder'
        }
      },
      {
        path: 'rubbishBox',
        name: 'Recycle',
        component: () => import('@/views/EmailList.vue'),
        meta: {
          title: 'layout.recycle'
        }
      },
    ]
  },
  {
    path: '/CrmEmail/writeEmail',
    component: Layout,
    children: [{
      path: '/',
      name: 'WriteEmail',
      component: () => import('@/views/WriteEmail.vue'),
      meta: {
        title: 'layout.sendEmail'
      }
    }]
  },
  {
    path: '/CrmEmail/emailInfo',
    component: Layout,
    children: [{
      path: ':emailInfoId',
      name: 'ViewEmail',
      component: () => import('@/views/EmailInfo.vue'),
      meta: {
        title: 'layout.emailInfo'
      }
    }]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router