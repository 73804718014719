/**
 * 获取当前时间
 */
export function getNow(s) {
    return s < 10 ? '0' + s : s;
}

export function getDateTime() {
    var myDate = new Date();
    //获取当前年
    var year = myDate.getFullYear();
    //获取当前月
    var month = myDate.getMonth() + 1;
    //获取当前日
    var date = myDate.getDate();
    var h = myDate.getHours(); //获取当前小时数(0-23)
    var m = myDate.getMinutes(); //获取当前分钟数(0-59)
    var s = myDate.getSeconds();
    return year + '-' + getNow(month) + "-" + getNow(date) + " " + getNow(h) + ':' + getNow(m) + ":" + getNow(s);
}

/**
 * 时间戳转换
 */
export function formatDate(date, fmt = 'yyyy-MM-dd hh:mm:ss') {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
    }
    return fmt;
}

/**
 * 解析前后端交互json字符串
 */
export function jsonReplace(json) {
    return JSON.parse(json.replace(/&quot;/g, '"'));
}

/**
 * 新增,或者更新tab
 */
export function addTab(option = 'Index', action = 'welCome', name = '首页', param) {
    parent.mainVue.addTab({
        op: option,
        act: action,
        name: name,
        param: param
    });
}

/**
 * 切换tab，方法
 */
export function changeTab(option = 'Index', action = 'welcome', name = '首页', param = null) {
    let tabName = (option + '-' + action).toLowerCase();
    let par = parent.mainVue;
    //如果存在
    if (par.tableTabsHave.indexOf(tabName) >= 0) {
        par.changeTab(tabName, param);
        return;
    }
    //不存在
    par.addTab({
        op: option,
        act: action,
        name: name,
        param: param
    });
}

export function replaceTab(option = 'Index', action = 'welcome', name = '首页', param = null) {
    let tabName = (option + '-' + action).toLowerCase();
    let par = parent.mainVue;
    //如果存在
    if (par.tableTabsHave.indexOf(tabName) >= 0) {
        parent.mainVue.removeTab((option + '-' + action).toLowerCase());
    }
    //不存在
    par.addTab({
        op: option,
        act: action,
        name: name,
        param: param
    });
}
/**
 * 关闭当前tab
 */
export function removeTab(option = 'Index', action = 'welCome') {
    setTimeout(() => {
        parent.mainVue.removeTab((option + '-' + action).toLowerCase());
    })
}

/**
 * 子框架获取当前页面参数
 */
export function getTabParam() {
    let data = parent.mainVue.tableTabs;
    let tabName = parent.mainVue.tableTabsValue;
    for (let i in data) {
        if (data[i].name == tabName) {
            return data[i].dataJson;
        }
    }
    return;
}

/**
 * 子框架设置当前页面参数
 */
export function setTabParam(param) {
    let data = parent.mainVue.tableTabs;
    let tabName = parent.mainVue.tableTabsValue;
    for (let i in data) {
        if (data[i].name == tabName) {
            data[i].dataJson = param;
        }
    }
    return;
}

/**
 * 跳转到语言包编辑页
 */
export function jumpLangInfo(id) {
    changeTab("Lang", 'langLabelInfo', '语言包编辑', {
        id: id,
        isTab: true
    })
}

/**
 * 请求成功失败通知
 */
export function toMessage(type = 'success', msg = 'success', showClose = true) {
    if (msg == 'success') {
        msg = parent.mainVue.toMessageSuccess;
    }
    parent.mainVue.$message({
        type: type,
        message: msg,
        showClose: showClose
    })
}

/**
 * 生成选择图片对话框，使用时在vue的create里面调用，data里面需要参数imgDialog
 */
// export function uploadImg(path = '', callback = 'imgCallBack', openPath = '../Plugs/selectImg') {
//     let param = {
//         path: path,
//         callback: callback,
//     };
//     param = JSON.stringify(param);

//     let diaLogHtml =
//         `<el-dialog :visible.sync="imgDialog" width="50vw" class="uploadImgDialog">
//          <iframe src="" frameborder="0" scrolling="yes" class="imgIframe" name="imgIframe" height="100%" width="100%"></iframe>
//          </el-dialog>
//          <input class="imgDialogInput" data-param=${param} hidden/>`;
//     $('#app').append(diaLogHtml);
//     $('.uploadImgDialog iframe').attr('src', openPath);
// }

/**
 * 打开上传图片对话框
 */
// export function openUploadImg(path = null, callback = 'imgCallBack') {
//     vue.imgDialog = true;

//     //防止图片框架没有加载好，递归调用，直到数据落位
//     if (!window.frames['imgIframe']) {
//         setTimeout(function () {
//             openUploadImg(path, callback);
//         }, 100)
//     } else {
//         if (!window.frames['imgIframe'].imgVue) {
//             setTimeout(function () {
//                 openUploadImg(path, callback);
//             }, 100)
//         } else {
//             if (path) {
//                 window.frames['imgIframe'].imgVue.path = path;
//             }
//             if (callback) {
//                 window.frames['imgIframe'].imgVue.callBack = callback;
//             }
//         }
//     }
// }

/**
 * 打开选择sku对话框
 */
// export function openSelectSku(index = null, table = 'table', bom = false, row, is_dialog) {
//     //防止框架没有加载好，递归调用，直到数据落位
//     if (!window.frames['selectSkuIframe']) {
//         setTimeout(() => {
//             openSelectSku(index);
//         }, 100)
//     } else {
//         if (!window.frames['selectSkuIframe'].selectSkuVue) {
//             setTimeout(() => {
//                 openSelectSku(index);
//             }, 100)
//         } else {
//             if (index != null) {
//                 window.frames['selectSkuIframe'].selectSkuVue.index = index;
//                 window.frames['selectSkuIframe'].selectSkuVue.is_dialog = is_dialog;
//             }
//             if (table) {
//                 window.frames['selectSkuIframe'].selectSkuVue.table = table;
//             }
//             if (bom) {
//                 window.frames['selectSkuIframe'].selectSkuVue.bom = true;
//                 window.frames['selectSkuIframe'].selectSkuVue.row = row;
//             }
//             window.frames['selectSkuIframe'].selectSkuVue.showTab()
//         }
//     }
// }


/**
 * 生成选择用户对话框，使用时在vue的create里面调用，data里面需要参数selectUserDialog
 */
// export function selectUserList(path = '', callback = 'userCallBack', openPath = '../Plugs/selectUserList') {
//     let param = {
//         path: path,
//         callback: callback,
//     };
//     param = JSON.stringify(param);

//     let diaLogHtml =
//         `<el-dialog :visible.sync="selectUserDialog" width="50vw" class="selectUserDialog">
//          <iframe src="" frameborder="0" scrolling="yes" class="selectUserIframe" name="selectUserIframe" height="100%" width="100%"></iframe>
//          </el-dialog>
//          <input class="selectUserInput" data-param=${param} hidden/>`;
//     $('#app').append(diaLogHtml);
//     $('.selectUserDialog iframe').attr('src', openPath);
// }

/**
 * 打开选择商品对话框
 */
// export function openSelectUser(path = null, callback = null) {
//     vue.selectUserDialog = true;

//     //防止框架没有加载好，递归调用，直到数据落位
//     if (!window.frames['selectUserIframe']) {
//         setTimeout(function () {
//             openSelectUser(path, callback);
//         }, 100)
//     } else {
//         if (!window.frames['selectUserIframe'].selectUserVue) {
//             setTimeout(function () {
//                 openSelectUser(path, callback);
//             }, 100)
//         } else {
//             if (path) {
//                 window.frames['selectUserIframe'].selectUserVue.path = path;
//             }
//             if (callback) {
//                 window.frames['selectUserIframe'].selectUserVue.callBack = callback;
//             }
//         }
//     }
// }

/**
 * 生成选择语言标签对话框，使用时在vue的create里面调用，data里面需要参数selectLangItemDialog
 */
// export function selectLangItem(path = '', callback = 'langItemCallBack', openPath = '../Plugs/selectLangItem') {
//     let param = {
//         path: path,
//         callback: callback,
//     };
//     param = JSON.stringify(param);

//     let diaLogHtml =
//         `<el-dialog :visible.sync="selectLangItemDialog" width="75%" class="selectLangItemDialog">
//          <iframe src="" frameborder="0" scrolling="yes" class="selectLangItemIframe" name="selectLangItemIframe" height="100%" width="100%"></iframe>
//          </el-dialog>
//          <input class="selectLangItemInput" data-param=${param} hidden/>`;
//     $('#app').append(diaLogHtml);
//     $('.selectLangItemDialog iframe').attr('src', openPath);
// }

/**
 * 打开选择商品对话框
 */
// export function openSelectLangItem(useType = 0, path = null, callback = null) {
//     vue.selectLangItemDialog = true;

//     //防止框架没有加载好，递归调用，直到数据落位
//     if (!window.frames['selectLangItemIframe']) {
//         setTimeout(function () {
//             openSelectLangItem(useType, path, callback);
//         }, 100)
//     } else {
//         if (!window.frames['selectLangItemIframe'].selectLangItemVue) {
//             setTimeout(function () {
//                 openSelectLangItem(useType, path, callback);
//             }, 100)
//         } else {
//             if (path) {
//                 window.frames['selectLangItemIframe'].selectLangItemVue.path = path;
//             }
//             if (callback) {
//                 window.frames['selectLangItemIframe'].selectLangItemVue.callBack = callback;
//             }

//             window.frames['selectLangItemIframe'].selectLangItemVue.lang_type = useType;

//             //调用刷新方法
//             window.frames['selectLangItemIframe'].selectLangItemVue.getMenus();
//         }
//     }
// }

/**
 * post方式跳转新页面
 */
export function Post(URL, PARAMTERS) {
    //创建form表单
    var temp_form = document.createElement("form");
    temp_form.action = URL;
    //如需打开新窗口，form的target属性要设置为'_blank'
    temp_form.target = "_blank";
    temp_form.method = "post";
    temp_form.style.display = "none";
    //添加参数
    for (var item in PARAMTERS) {
        var opt = document.createElement("textarea");
        opt.name = PARAMTERS[item].name;
        opt.value = PARAMTERS[item].value;
        temp_form.appendChild(opt);
    }
    document.body.appendChild(temp_form);
    //提交数据
    temp_form.submit();
}

/**
 * 验证当前模块是否有权限显示
 */
export function rightShow(str) {
    str = ',' + str.toLowerCase() + ',';
    if (parent.mainVue.adminMenusStr.indexOf(str) >= 0) {
        return true;
    }
    return false;
}

/**
 * 获取不重复字符串
 * @returns {string}
 */
export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return s4() + s4() + s4() + s4();
}

/**
 * 子框架或插件验证是否显示权限
 */
export function plugRightShow(str) {
    str = ',' + str.toLowerCase() + ',';
    if (parent.parent.mainVue.adminMenusStr.indexOf(str) >= 0) {
        return true;
    }
    return false;
}

/**
 * 保存用户习惯到后端
 */
export function savePageOption(option, URL) {
    //option 为 {'name': '页面名称（文件_页面）', 'option' => ['name', 'date', 'addr']} URL为"{$webPath}"
    let setUrl = ''
    if (URL) {
        setUrl = "/" + URL + "/home/Plugs/setPageOption";
    } else {
        setUrl = "/home/Plugs/setPageOption";
    }
    return setUrl;
}