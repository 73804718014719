export default {
  menusAll: [{
      index: 0,
      name: "layout.sale",
      icon: "icon-xiaoshoutixi"
    },
    {
      index: 1,
      name: "layout.purchase",
      icon: "icon-caigoudingdan"
    },
    {
      index: 2,
      name: "layout.produce",
      icon: "icon-biaozhunhuashengchan"
    },
    {
      index: 3,
      name: "layout.warehouse",
      icon: "icon-cangku"
    },
    {
      index: 4,
      name: "layout.finance",
      icon: "icon-log"
    },
    {
      index: 5,
      name: "layout.crm",
      icon: "icon-CRM",
      children: [{
          id: 0,
          name: "layout.organization",
          icon: "icon-zuzhi1",
          children: [{
            id: 0,
            name: "layout.organizationStructure",
            op: "CrmOther",
            act: "crmManagement",
            path: "crmMangement",
          }],
        },
        {
          id: 1,
          name: "layout.project",
          icon: "icon-xiangmu2",
          children: [{
              id: 0,
              name: "layout.projectsList",
              op: "CrmTask",
              act: "projectManagement",
            },
            {
              id: 1,
              name: "layout.newProject",
              op: "CrmTask",
              act: "projectInfo",
            }
          ]
        },
        {
          id: 2,
          name: "layout.tasks",
          icon: "icon-renwu1",
          children: [{
              id: 0,
              name: "layout.tasksList",
              op: "CrmTask",
              act: "taskManagement",
            },
            {
              id: 1,
              name: "layout.createTask",
              op: "CrmTask",
              act: "taskInfo",
            }
          ]
        },
        {
          id: 3,
          name: "layout.customersAndLeads",
          icon: "icon-kehuguanli",
          children: [{
              id: 0,
              name: "layout.customerList",
              op: "CrmContact",
              act: "contactList",
            },
            {
              id: 1,
              name: "layout.createCustomer",
              op: "CrmContact",
              act: "contactInfo",
            },
            {
              id: 2,
              name: "layout.kanbanLeads",
              op: "CrmContact",
              act: "cluePanel",
            },
            {
              id: 3,
              name: "layout.leadsList",
              op: "CrmContact",
              act: "clueList",
            },
            {
              id: 4,
              name: "layout.createLead",
              op: "CrmContact",
              act: "clueDetails",
            },
          ]
        },
        {
          id: 4,
          name: "layout.email",
          icon: "icon-youxiang",
          children: [{
              id: 0,
              name: "layout.inbox",
              op: "CrmEmail",
              act: "inbox",
            },
            {
              id: 1,
              name: "layout.outbox",
              op: "CrmEmail",
              act: "sendBox",
            },
            {
              id: 2,
              name: "layout.folder",
              op: "CrmEmail",
              act: "folder",
            },
            {
              id: 3,
              name: "layout.recycle",
              op: "CrmEmail",
              act: "rubbishBox",
            },
            {
              id: 4,
              name: "layout.sendEmail",
              op: "CrmEmail",
              act: "writeEmail",
            },
          ]
        }
      ]
    },
    {
      index: 6,
      name: "layout.products",
      icon: "icon-ziliaoku"
    },
    {
      index: 7,
      name: "layout.setting",
      icon: "icon-xitong"
    },
  ]
}